import React, { useEffect, useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Root from "templates/Root";
import styled from "styled-components";
import PageTitle from "components/PageTitle";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import expertsListData from "data/expertsList";
import { voivodeships } from "constans/voivodeships";
import Loader from "components/Loader";
import { permissionsGroup } from "constans/permissionsGroup";
import Card from "@material-ui/core/Card";

const StyledWrapper = styled.div`
  margin: 0 30px;
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: flex;
  flex-direction: column;
`;

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.primary} !important;
  ${({ center }) =>
    center &&
    `
    text-align: center !important;
  `}
`;

const StyledHeaderTableCell = styled(TableCell)`
  text-align: center !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  font-weight: ${({ theme }) => theme.fontWeights.bold} !important;
`;

const StyledHeaderTableCellInner = styled.div`
  display: flex;
  & .group,
  & .permission {
    padding: 0 16px;
  }
  & .group {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .permission {
    flex-basis: 100px;
  }
`;

const StyledExpertLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledTableCard = styled(Card)`
  margin-bottom: 50px;
`;

const StyledExpansionPanelWrapper = styled.div`
  margin-bottom: 50px;
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  & .group {
    flex-grow: 1;
    margin-left: 10px;
  }
  & .permission {
    min-width: 100px;
    padding: 0 16px;
  }
  &:before {
    content: "";
    align-self: flex-start;
    margin-top: 7.5px;
    min-width: 5px;
    min-height: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledProjectGroupsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const generalAndMunicipalConstructionProjectsPermissionTo2008 = ["1.1", "1.2"];
const ExpertsList = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const { t } = useTranslation();

  const tableHeaders = [
    { name: t("pages.experts_list.table.full_name") },
    { name: t("pages.experts_list.table.address") },
    { name: t("pages.experts_list.table.phone") },
    { name: t("pages.experts_list.table.permissions") },
    { name: t("pages.experts_list.table.email") },
  ];

  const permissionGroupTableHeaders = [
    { name: t("pages.experts_list.permissions.construction_types") },
    {
      name: t("pages.experts_list.permissions.project_groups"),
      name2: t(
        "pages.experts_list.permissions.designation_of_appraisers_groups_of_rights_to_health_and_safety_issues"
      ),
    },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (voivodeships?.length && expertsListData.expertsList?.length) {
      const preparedTableData = voivodeships.map((voivodeship) => {
        return {
          name: voivodeship.name,
          label: voivodeship.label,
          experts: expertsListData.expertsList.filter(
            (item) => item["Województwo"] === voivodeship.name && item.rodo
          ),
        };
      });
      setTableData(preparedTableData);
    }
  }, [voivodeships, expertsListData]);

  const renderGeneralAndMunicipalConstructionProjectsGroupTo2008 = (
    name,
    permissionNumber
  ) => {
    if (
      generalAndMunicipalConstructionProjectsPermissionTo2008.includes(
        permissionNumber
      )
    ) {
      return (
        <>
          <StyledProjectGroupsWrapper>
            {" "}
            {name && name?.map((item) => <span>{item}</span>)}
          </StyledProjectGroupsWrapper>
          <span className="permission">{permissionNumber}</span>
        </>
      );
    } else {
      return (
        <>
          <span className="group">{name}</span>
          <span className="permission">{permissionNumber}</span>
        </>
      );
    }
  };
  return (
    <Root>
      <StyledWrapper>
        <PageTitle title={t("pages.experts_list.title")} />
        {tableData.length ? (
          <>
            <StyledExpansionPanelWrapper>
              {tableData?.map((item) => (
                <ExpansionPanel key={item?.name}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{item?.label}</Typography>
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <TableContainer>
                      <Table aria-label="simple table" color="primary">
                        <TableHead>
                          <TableRow>
                            {tableHeaders.map((item) => (
                              <StyledHeaderTableCell>
                                {item.name}
                              </StyledHeaderTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.experts?.map((row) => (
                            <TableRow key={row.email}>
                              <StyledTableCell component="th" scope="row">
                                {row["Nazwisko i Imie"]}
                              </StyledTableCell>
                              <StyledTableCell>{row["Adres"]}</StyledTableCell>
                              <StyledTableCell>
                                <StyledExpertLink
                                  href={`tel:${row["Telefon"]}`}
                                >
                                  {row["Telefon"]}
                                </StyledExpertLink>
                              </StyledTableCell>
                              <StyledTableCell>
                                {row["Uprawnienia"]}
                              </StyledTableCell>
                              <StyledTableCell>
                                <StyledExpertLink href={`mailto:${row.email}`}>
                                  {row.email}
                                </StyledExpertLink>
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </StyledExpansionPanelWrapper>
            <PageTitle
              title={t("pages.experts_list.permissions.designation_from_2008")}
            />
            <StyledTableCard>
              <TableContainer>
                <Table aria-label="simple table" color="primary">
                  <TableHead>
                    <TableRow>
                      {permissionGroupTableHeaders.map(({ name, name2 }) => (
                        <StyledHeaderTableCell>
                          {name2 ? (
                            <StyledHeaderTableCellInner>
                              <span className="group">{name}</span>
                              <span className="permission">{name2}</span>
                            </StyledHeaderTableCellInner>
                          ) : (
                            <span className="group">{name}</span>
                          )}
                        </StyledHeaderTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsGroup.from2008?.map((row) => (
                      <TableRow key={row.name}>
                        <StyledTableCell component="th" scope="row" center>
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <ul>
                            {row.project_groups.map((item) => (
                              <StyledListItem key={item.name}>
                                <span className="group">{item.name}</span>
                                <span className="permission">
                                  {item.permission_number}
                                </span>
                              </StyledListItem>
                            ))}
                          </ul>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledTableCard>
            <PageTitle
              title={t("pages.experts_list.permissions.designation_to_2008")}
            />
            <StyledTableCard>
              <TableContainer>
                <Table aria-label="simple table" color="primary">
                  <TableHead>
                    <TableRow>
                      {permissionGroupTableHeaders.map(({ name, name2 }) => (
                        <StyledHeaderTableCell>
                          {name2 ? (
                            <StyledHeaderTableCellInner>
                              <span className="group">{name}</span>
                              <span className="permission">{name2}</span>
                            </StyledHeaderTableCellInner>
                          ) : (
                            <span className="group">{name}</span>
                          )}
                        </StyledHeaderTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsGroup.to2008?.map((row) => (
                      <TableRow key={row.name}>
                        <StyledTableCell component="th" scope="row" center>
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <ul>
                            {row.project_groups.map((item) => (
                              <StyledListItem key={item.name}>
                                {renderGeneralAndMunicipalConstructionProjectsGroupTo2008(
                                  item.name,
                                  item.permission_number
                                )}
                              </StyledListItem>
                            ))}
                          </ul>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledTableCard>
          </>
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </StyledWrapper>
    </Root>
  );
};

export default ExpertsList;
