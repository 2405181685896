import React, {useEffect} from "react";
import Root from "templates/Root";
import Card from "@material-ui/core/Card";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import PageTitle from "components/PageTitle";

const StyledWrapper = styled.div`
  margin: 0 30px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px; 
  padding: 20px; 
  line-height: 1.5; 
  font-weight: ${({theme}) => theme.fontWeights.medium};
  overflow-wrap: anywhere;
`;

const StyledSubtitle = styled.p`
  margin-top: 8px;
  text-decoration: underline;
`;

const News = () => {
  useEffect(() => window.scrollTo(0,0), [])
  const { t } = useTranslation();
  const news = [
    {text: t('pages.news.news_1'), subtitle: t('pages.news.news_1_subtitle')},
    {text: t('pages.news.news_2'), subtitle: t('pages.news.news_2_subtitle')},
    {text: t('pages.news.news_3'), subtitle: t('pages.news.news_3_subtitle')},
    {text: t('pages.news.news_4'), subtitle: t('pages.news.news_4_subtitle')},
    {text: t('pages.news.news_5'), subtitle: t('pages.news.news_5_subtitle')},
    {text: t('pages.news.news_6'), subtitle: t('pages.news.news_6_subtitle')},
    {text: t('pages.news.news_7'), subtitle: t('pages.news.news_7_subtitle')},
  ]
  return (
    <Root>
      <StyledWrapper>
        <PageTitle title={t('page_nav.news')}/>
        {news.map(item => (
          <StyledCard key={item.text}>
            {item.text}
            {item.subtitle && <StyledSubtitle>{item.subtitle}</StyledSubtitle>}
          </StyledCard>
        ))}
      </StyledWrapper>
    </Root>
  );
};

export default News;
